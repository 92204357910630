import { type ServerResponse } from "@infrastructure/api/serverCall"
import Layout from "../layout/Layout/Layout"
import Meta from "../layout/Meta"
import { type RegistrationFormDetailWithValues } from "@services/registrations/registrationFormDetail"
import { type RegistrationFormConfig } from "./form/RegistrationFormConfig"
import { BoughtItems } from "common"
import { FormDefaults } from "./form/formDefaults"
import { RegistrationFormWithSubmitHandler } from "./form/RegistrationFormWithSubmitHandler"
import { type RegistrationFormData } from "@services/registrations/RegistrationFormData"
import { routes } from "../routes"
import { PageContentLoader } from "../components/PageContentLoader"
import { RaceStatus } from "common"
import { responseRenderSwitch } from "@infrastructure/api/responseRenderSwitch"
import Container from "../components/Container/Container"
import { formatDate } from "utils"
import { CanonicalUrl } from "../layout/CanonicalUrl"
import { type ServerValidationErrors } from "@services/registrations/validation/serverValidationError"
import { RegistrationInfoMessage } from "./RegistrationInfoMessage"
import { Component } from "react"
import { getOpenGraphImageUrlByPublicId } from "@infrastructure/cloudinary/cloudinaryUtils"
import LinkWithArrow from "@website/components/LinkWithArrow/LinkWithArrow"
import { type RegistrationSuccess } from "reg-utils"
import { SetBranding } from "@website/components/Branding/Branding"

export interface AdditionalRegistrationFormConfig
  extends Pick<RegistrationFormConfig, "getItemDisabled" | "submitButtonTitle" | "submitButtonTitleWithExistingEmail" | "eshopMode" | "showTotalPrice"> {
  submitData: (data: RegistrationFormData, datail: RegistrationFormDetailWithValues) => Promise<ServerResponse<RegistrationSuccess, { errors: ServerValidationErrors }>>
  redirectPathAfterSubmit: (params: { guid: string }) => string
}

interface RegistrationPageBaseProps {
  response?: ServerResponse<RegistrationFormDetailWithValues, unknown>
  title: (detail: RegistrationFormDetailWithValues) => string
  header: (detail: RegistrationFormDetailWithValues) => React.ReactNode
  validateSubraceStatus: (detail: RegistrationFormDetailWithValues) => string | undefined
  config: AdditionalRegistrationFormConfig
}

export class RegistrationPageBase extends Component<RegistrationPageBaseProps> {
  render() {
    return <Layout smoothScroll>{this.renderContent()}</Layout>
  }

  private renderContent() {
    return responseRenderSwitch(this.props.response, this.renderLoader, regFormDetail => {
      if (regFormDetail.racestatus === RaceStatus.Archived) {
        return this.renderMessage("Závod už proběhl a není možné se na něj registrovat.", regFormDetail)
      }
      const validationError = this.props.validateSubraceStatus(regFormDetail)
      if (validationError) {
        return this.renderMessage(validationError, regFormDetail)
      }

      return this.renderRegistrationForm(regFormDetail)
    })
  }

  private renderRegistrationForm(regFormDetail: RegistrationFormDetailWithValues) {
    const config: RegistrationFormConfig = {
      subraceId: regFormDetail.subraceid,
      type: regFormDetail.type,
      formItems: regFormDetail.formitems,
      racePrice: regFormDetail.price,
      values: regFormDetail.formdata ?? FormDefaults.getDefaults(regFormDetail.formitems),
      shopItems: regFormDetail.eshopitems,
      boughtItems: regFormDetail.boughtitems ? BoughtItems.fromJson(regFormDetail.boughtitems) : BoughtItems.empty,
      raceRulesUrl: regFormDetail.racerulesurl,
      limits: regFormDetail.limits,
      usedCouponValue: regFormDetail.usedCouponValue,
      header: this.props.header(regFormDetail),
      madeByOrganizer: false,
      ...this.props.config
    }
    return (
      <>
        <Container>
          <Meta
            title={this.props.title(regFormDetail)}
            description={`Registrace (přihlášky) na trať ${regFormDetail.subracename} závodu ${regFormDetail.racename}. Závod bude ${formatDate(regFormDetail.datetime)}${
              regFormDetail.place ? ` v ${regFormDetail.place}` : ""
            }`}
            image={getOpenGraphImageUrlByPublicId(regFormDetail.raceheroimagepublicid)}
          />
          <SetBranding branding={regFormDetail.branding} />
          <CanonicalUrl relativeUrl={routes.registration.getLink(regFormDetail.raceslug, regFormDetail.subraceslug)} />
          <RegistrationFormWithSubmitHandler
            submitData={data => this.props.config.submitData(data, regFormDetail)}
            redirectPathAfterSubmit={this.props.config.redirectPathAfterSubmit}
            subRaceId={regFormDetail.subraceid}
            config={config}
          />
        </Container>
      </>
    )
  }

  private renderMessage(message: string, regFormDetail: RegistrationFormDetailWithValues) {
    return (
      <RegistrationInfoMessage
        title="Registrace není možná :("
        extra={
          <LinkWithArrow arrow="left" to={routes.race.getLink(regFormDetail.raceslug)}>
            Zpět na detail závodu {regFormDetail.racename}
          </LinkWithArrow>
        }
      >
        {message}
      </RegistrationInfoMessage>
    )
  }

  private renderLoader = () => {
    return <PageContentLoader />
  }
}
