import Helmet from "react-helmet"

import { getBrandingColors, getBrandingDarkModeColors, type OptionalBranding } from "common"

interface Props {
  branding: OptionalBranding
}

export const SetBranding = ({ branding }: Props) => {
  const colors = getBrandingColors(branding)
  const darkModeColors = getBrandingDarkModeColors(branding)

  return (
    <Helmet>
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color={colors.primary ? `rgb(${colors.primary})` : "#9f3ef5"} />
      <meta name="msapplication-TileColor" content={colors.primary ? `rgb(${colors.primary})` : "#9f3ef5"} />
      <meta name="theme-color" content={colors.primary ? `rgb(${colors.primary})` : "#9f3ef5"} />
      <style type="text/css" data-react-helmet="true">
        {`
          :root {
            ${colors.primary ? `--theme-primary: ${colors.primary}` : ""};
            ${colors.secondary ? `--theme-secondary: ${colors.secondary}` : ""};
            ${colors.textPrimary ? `--theme-text-primary: ${colors.textPrimary}` : ""};
            ${colors.textSecondary ? `--theme-text-secondary: ${colors.textSecondary}` : ""};
            ${darkModeColors.primary ? `--theme-dark-primary: ${darkModeColors.primary}` : ""};
            ${darkModeColors.secondary ? `--theme-dark-secondary: ${darkModeColors.secondary}` : ""};
            ${darkModeColors.textPrimary ? `--theme-dark-text-primary: ${darkModeColors.textPrimary}` : ""};
            ${darkModeColors.textSecondary ? `--theme-dark-text-secondary: ${darkModeColors.textSecondary}` : ""};
          }
        `}
      </style>
    </Helmet>
  )
}
