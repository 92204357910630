import { Card } from "@website/components/Card/Card"
import Container from "@website/components/Container/Container"
import Heading from "@website/components/Heading/Heading"

export const RegistrationInfoMessage: React.FC<{ title?: string; children: React.ReactNode; extra?: React.ReactNode }> = ({ title, children, extra }) => (
  <Container>
    <Card padding="large" type="warning">
      {title && (
        <Heading type="h1" brand marginBottom>
          {title}
        </Heading>
      )}
      <p className="mb-4">{children}</p>
      {extra && <p>{extra}</p>}
    </Card>
  </Container>
)
