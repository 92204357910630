import { PureComponent } from "react"
import { RegistrationForm } from "./RegistrationForm"
import { type ServerValidationErrors } from "@services/registrations/validation/serverValidationError"
import { type RegistrationFormConfig } from "./RegistrationFormConfig"
import { type FormValues } from "common/form-items"
import { type BoughtItems } from "common"
import { ResponseType, type ServerResponse } from "@infrastructure/api/serverCall"
import { type RegistrationSuccess } from "reg-utils"
import { Redirect } from "react-router"
import { defaultRegistrationFormCompoments } from "./RegistrationFormComponents"
import { type RegistrationFormData } from "@services/registrations/RegistrationFormData"
import { getClientLogger } from "@infrastructure/client-logger/clientLogger"

export interface Props {
  subRaceId: number
  config: RegistrationFormConfig
  submitData: (data: RegistrationFormData) => Promise<ServerResponse<RegistrationSuccess, { errors: ServerValidationErrors }>>
  redirectPathAfterSubmit: (params: { guid: string }) => string
}

interface State {
  validationErrors: ServerValidationErrors
  isSubmitting: boolean
  submittedWithGuid?: string
  alreadyUsedEmail?: string
}

export class RegistrationFormWithSubmitHandler extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { validationErrors: {}, isSubmitting: false, submittedWithGuid: undefined }
  }

  submitHandler = async (values: FormValues, boughtItems: BoughtItems) => {
    try {
      const data: RegistrationFormData = {
        values: values,
        boughtItems: boughtItems,
        emailColisionConfirmed: !!this.state.alreadyUsedEmail
      }

      this.setState({ isSubmitting: true, validationErrors: {} })
      const response = await this.props.submitData(data)

      if (response.type === ResponseType.ok) {
        this.setState({ submittedWithGuid: response.data.guid })
        return
      }

      if (response.type === ResponseType.validationError) {
        this.setState({ validationErrors: response.error.errors })
        return
      }

      if (response.type === ResponseType.conflict && !this.state.alreadyUsedEmail) {
        this.setState({ alreadyUsedEmail: response.data.email })
        return
      }
      throw new Error(`Neznámá chyba odesílání: ${JSON.stringify(response)}`)
    } catch (err) {
      alert("Nepodařilo se odeslat formulář. Zkuste to, prosím, za chvíli. Chyba: " + err)
      getClientLogger().error("Error in registration form submit", err as Error)
      throw err
    } finally {
      this.setState({ isSubmitting: false })
    }
  }

  private clearServerValidationError = (fieldName: string) => {
    this.setState(s => {
      const { [fieldName]: _a, ...newValidationErrors } = s.validationErrors
      return { ...s, validationErrors: newValidationErrors }
    })
  }

  render() {
    const { config } = this.props
    if (this.state.submittedWithGuid) {
      return <Redirect to={this.props.redirectPathAfterSubmit({ guid: this.state.submittedWithGuid })} />
    }
    return (
      <RegistrationForm
        config={config}
        components={defaultRegistrationFormCompoments}
        submitHandler={this.submitHandler}
        validationErrors={this.state.validationErrors}
        submitting={this.state.isSubmitting}
        alreadyUsedEmail={this.state.alreadyUsedEmail}
        clearServerValidationError={this.clearServerValidationError}
        inCardWrapper
      />
    )
  }
}
