import { type ServerResponse, ResponseType } from "./serverCall"

export function responseRenderSwitch<RES>(response: ServerResponse<RES, unknown> | undefined, loaderRender: () => React.ReactNode, dataRender: (data: RES) => React.ReactNode): React.ReactNode {
  if (!response) {
    return loaderRender()
  }
  if (response.type === ResponseType.ok) {
    return dataRender(response.data)
  }
  throw new Error("Unhandled response type")
}
