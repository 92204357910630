import SendInfoLinkParagraph from "@website/racer/SendInfoLinkParagraph"
import { routes } from "@website/routes"
import { Card } from "../Card/Card"
import Heading from "../Heading/Heading"
import Link from "../Link/Link"

interface Props {
  raceName: string
  raceSlug: string
  showSelfEditPromo: boolean
  subraceName: string
  title: string
}

export const SubRaceHeader: React.FC<Props> = ({ title, raceName, subraceName, raceSlug, showSelfEditPromo }) => (
  <div className="mb-8 grid items-center gap-y-8 md:mb-16 lg:grid-cols-2 lg:gap-x-16">
    <Heading type="h1">
      {title}{" "}
      <span className="block text-2xl">
        <span className="text-brand-primary dark:text-brand-dark-primary">{subraceName}</span> – <Link to={routes.race.getLink(raceSlug)}>{raceName}</Link>
      </span>
    </Heading>
    {showSelfEditPromo && (
      <div className="-order-1 lg:order-1">
        <Card boxShadow="none" type="warning" padding="medium">
          <SendInfoLinkParagraph text="Už jste se registrovali, ale ztratil se vám potvrzující e-mail? Potřebujete něco upravit, nebo poslat znovu informace o platbě?" />
        </Card>
      </div>
    )}
  </div>
)
